/* Animation elements */

function parallax(e) {
	this.querySelectorAll('[class^="element-"]').forEach((layer) => {
		const speed = layer.getAttribute("data-speed");
		const x = (window.innerWidth - e.pageX * speed) / 1000;
		layer.style.transform = `translateX(${x}px)`;
	});
}

document.addEventListener("mousemove", parallax);

/* Animation */

function reveal() {
	const reveals = document.querySelectorAll(".reveal");

	for (let i = 0; i < reveals.length; i++) {
		var windowHeight = window.innerHeight;
		var elementTop = reveals[i].getBoundingClientRect().top;
		var elementVisible = 150;

		if (elementTop < windowHeight - elementVisible) {
			reveals[i].classList.add("active");
		} else {
			reveals[i].classList.remove("active");
		}
	}
}

window.addEventListener("scroll", reveal);
reveal();
